<template>
  <div class="dredgePage">
    <CellGroup :title="ifEdit?'修改车队信息':'申请开通车队卡'">
      <field v-model="companyName" label="公司名称" placeholder="请输入公司名称" input-align="right" />
      <field v-model="Name" label="联系人" placeholder="请输入联系人姓名" input-align="right" />
      <field v-model="phone" label="手机号" placeholder="请输入联系人手机号" input-align="right" type="tel" />
      <field
        label-width="120"
        v-model="price"
        label="预估月加油金额"
        placeholder="预估月加油金额"
        input-align="right"
        type="number"
      >
        <template slot="button" v-if="price">
          <span>元</span>
        </template>
      </field>
    </CellGroup>
    <div class="submit">
      <Button plain hairline type="info" style="width:50%" @click="applySubmit">提交</Button>
    </div>
    <!-- 成功弹窗 -->
    <Popup v-model="show_success" class="success" :close-on-click-overlay="false">
      <div class="image">
        <span class="iconfont iconxinxitijiao"></span>
      </div>
      <div class="success_text">信息提交成功</div>
      <div class="success_info">信息已提交，请耐心等待审核</div>
      <div class="success_button" @click="$router.push('/carteamCard')">我知道了</div>
    </Popup>
  </div>
</template>

<script>
import creditshop from "@/api/creditshop";
import { Field, Button, Popup, CellGroup } from "vant";
export default {
  components: {
    Field,
    Button,
    Popup,
    CellGroup
  },
  data() {
    return {
      companyName: "",
      Name: "",
      phone: "",
      price: "",
      show_success: false,
      ifEdit: false,
      account_id: ""
    };
  },
  mounted() {
    if (this.$route.query.hasOwnProperty("type")) {
      this.ifEdit = true;
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    async getDetail() {
      try {
        const res = await creditshop.team_carddetail();
        if (res.code == 200) {
          this.companyName = res.data.name;
          this.Name = res.data.contact_name;
          this.phone = res.data.mobile;
          this.price = parseInt(res.data.estimated_amount);
          this.account_id = res.data.id;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    async applySubmit() {
      try {
        const res = await creditshop.apply_for_card(
          this.companyName,
          this.Name,
          this.phone,
          this.price,
          this.account_id
        );
        if (res.code == 200) {
          if (this.account_id) {
            this.$toast("修改成功");
            this.$router.replace("/carteamCard")
          } else {
            this.show_success = true;
          }
          
        } else {
          this.$toast(res.msg);
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dredgePage {
  height: 100%;
  background: #fbfbfb;
  .submit {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .success {
    width: 60%;
    padding: 0 13px;
    text-align: center;
    border-radius: 20px;
    padding-top: 15px;
    .image {
      margin: 0 auto;
      // margin-top: 35px;
      width: 84px;
      height: 84px;
      border: 1px solid #eee;
      line-height: 84px;
      border-radius: 50%;
      background: #ffaf0114;

      .iconxinxitijiao {
        color: rgb(45, 168, 14);
        font-size: 48px;
      }
    }
    .success_text {
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .success_info {
      font-size: 13px;
      line-height: 17px;
    }
    .success_button {
      margin: 14px auto 24px auto;
      width: 138px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-size: 13px;
      color: #fff;
      background: linear-gradient(
        -90deg,
        rgba(255, 128, 0, 1) 0%,
        rgba(255, 177, 1, 1) 100%
      );
    }
  }
}
</style>
